@import '../../assets/sass/_variables'

body
    font-family: 'Roboto'

.tooltip
    &.colour-swatch-info
        .tooltip-inner
            width: max-content
            max-width: 600px

.hljs   // Code Snippet Component
    .hljs-ln   
        .hljs-ln-line
            padding: 0 0 2px
                
        .hljs-ln-numbers
            .hljs-ln-n
                margin-right: 10px

        .hljs-ln-code
            .hljs-tag
                color: $secondary-green-40

                .hljs-name
                    color: $secondary-green-40

                .hljs-attr
                    color: $secondary-blue-40

                .hljs-string
                    color: $secondary-orange-40